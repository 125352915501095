// every dependency has to be imported here
// css, scss, image, fonts etc.
import bulma from "./assets/scss/bulma.scss"; // main bulma styles
import {
  Bulma
} from './classes/Bulma'; // bulma stuff. Navi etc.
import 'lazysizes'; // https://www.npmjs.com/package/lazysizes
import GLightbox from 'glightbox'; // https://www.npmjs.com/package/glightbox
import "../node_modules/glightbox/dist/css/glightbox.min.css";
import Splide from '@splidejs/splide'; // slider/carousel https://splidejs.com/
import "@splidejs/splide/dist/css/splide.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import youtubeDSGVO from './classes/youtubeDSGVO';
import Booking from './classes/booking';

/* init methods */
new Bulma().initNavbar();
//new Bulma().initDropdowns();
const lightbox = GLightbox({});

const splideEl = document.querySelector('.splide');
if (splideEl) {
  var splide = new Splide( '.splide', {
    type   : 'fade',
    autoplay: true,
    rewind: true,
    speed: 1000,
    arrows: false,
    interval:8000,
  } );
  splide.mount();
}

const splideGallery = document.querySelector('.splide-gallery');
if (splideGallery) {
  var splide = new Splide( '.splide-gallery', {
    type   : 'loop',
  padding: '20%',
  } );
  splide.mount();
}

if (document.querySelectorAll('.g360_ytembed') && document.querySelectorAll('.g360_ytembed').length > 0) {
  localStorage.setItem('g360_ytlisten', false); // listener not listening
  new youtubeDSGVO().init('');
}

if (document.querySelectorAll('.g360_booking') && document.querySelectorAll('.g360_booking').length > 0) {
  /* var instance = new Booking(); // create an obj
  instance.init(); // call its init() function */
  new Booking().init(); // call its init() function
}
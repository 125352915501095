export default class Booking {
  constructor() {
    this.partnerUrl = 'https://www.hrs.com/web3/hotelData.do?client=de__NEXT&activity=photo&hotelnumber=220760&customerId=1000939001';
    this.arrivalDate = null;
    this.departureDate = null;
    this.sr = null;
    this.dr = null;
    this.persons = null;
    
    // today
    const todayDate = new Date();
    let dd = todayDate.getDate();
    let mm = todayDate.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = todayDate.getFullYear();
    
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 
    
    this.today = yyyy+'-'+mm+'-'+dd;

  }
  
  nextPossibleDepartureDay() {
   
    let aDate = document.getElementById("arrivalDate").value;
    
    if (aDate === "") {
      aDate = this.today;
    }
  
    const date = new Date(aDate);
    date.setDate(date.getDate() + 1);
    
    let dd = date.getDate();
    let mm = date.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = date.getFullYear();
    
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 
    
    return yyyy+'-'+mm+'-'+dd;
 
  }
  
  
  nextPossibleArrivalDay() {
   
    let dDate = document.getElementById("departureDate").value;
    
    if (dDate !== "") {
 
      const date = new Date(dDate);
      date.setDate(date.getDate() - 1);
      
      let dd = date.getDate();
      let mm = date.getMonth()+1; //January is 0 so need to add 1 to make it 1!
      let yyyy = date.getFullYear();

      if(dd<10){
        dd='0'+dd
      } 
      if(mm<10){
        mm='0'+mm
      } 

      return yyyy+'-'+mm+'-'+dd;

    }
  }
  
  
  reCalculate() {
    
    this.arrivalDate = document.getElementById("arrivalDate").value;
    this.departureDate = document.getElementById("departureDate").value;
    this.sr = document.getElementById("sr").value;
    this.dr = document.getElementById("dr").value;
    this.persons = document.getElementById("persons").value;
    
    if (this.arrivalDate !== ""
       && this.departureDate !== ""
       && (this.sr !== "" || this.dr !== "")
       && this.persons !== "") {
      document.getElementById("sendrequest").removeAttribute('disabled')
    } else {
      document.getElementById("sendrequest").setAttribute('disabled', '');
    }
   
    // set min-date to today
    document.getElementById("arrivalDate").setAttribute("min", this.today);
    document.getElementById("arrivalDate").setAttribute("max", this.nextPossibleArrivalDay());
    document.getElementById("departureDate").setAttribute("min", this.nextPossibleDepartureDay());

  }
  
  init() {
    
    document.getElementById("arrivalDate").addEventListener("change", function(that) {
      this.reCalculate();
    }.bind(this));

    document.getElementById("departureDate").addEventListener("change", function() {
      this.reCalculate();
    }.bind(this));

    document.getElementById("sr").addEventListener("change", function() {
      this.reCalculate();
    }.bind(this));

    document.getElementById("dr").addEventListener("change", function() {
      this.reCalculate();
    }.bind(this));

    document.getElementById("persons").addEventListener("change", function() {
      this.reCalculate();
    }.bind(this));

    document.getElementById("sendrequest").addEventListener("click", function() {
      
      const arrival = document.getElementById("arrivalDate").value;
      const departure = document.getElementById("departureDate").value;
      // arrival
      const date = new Date(arrival);
      const aday = date.getDate();
      const amonth = date.getMonth()+1; //January is 0 so need to add 1 to make it 1!
      const ayear = date.getFullYear();
      // departure
      const ddate = new Date(departure);
      const dday = ddate.getDate();
      const dmonth = ddate.getMonth()+1; //January is 0 so need to add 1 to make it 1!
      const dyear = ddate.getFullYear();
      // rest
      let sr = document.getElementById("sr").value;
      let dr = document.getElementById("dr").value;
      const persons = document.getElementById("persons").value;

      if (sr === "") {
        sr = "0";
      }

      if (dr === "") {
        dr = "0";
      }
      
      const url = `${this.partnerUrl}&startDateDay=${aday}&startDateMonth=${amonth}&startDateYear=${ayear}&endDateDay=${dday}&endDateMonth=${dmonth}&endDateYear=${dyear}&currency=EUR&singleRooms=${sr}&doubleRooms=${dr}&adults=${persons}&themesDynamicPageParam=440655&availability=true`;
      window.open(url, '_blank').focus();
    }.bind(this));

    this.reCalculate();
  }
}